body {
  margin: 0;
  background-color: #1d1d1f;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}

::-webkit-scrollbar-thumb {
  background-color: white; /* White thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
