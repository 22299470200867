/* Fixed Plain CSS */

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 40%;
  left: 0;
  margin: 0 auto;
  font-family: "ravenna", sans-serif;
}

.container p {
  font-size: 20px;
}

.container a {
  display: inline-block;
  position: relative;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  top: 5px;
}

.container a:after {
  content: "";
  position: absolute;
  background: #ffffff;
  height: 2px;
  width: 0%;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  transition: 0.35s ease;
}

.container a:hover:after,
.container a:focus:after,
.container a:active:after {
  width: 100%;
}

h1 {
  position: relative;
  text-align: center;
  font-family: "ravenna", serif;
}

.button_container {
  position: fixed;
  top: 5%;
  right: 2%;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 900;
  transition: opacity 0.25s ease;
  color: #000;
}

.text_container {
  position: fixed;
  top: 5%;
  left: 2%;
  z-index: 100;
}

.button_container:hover {
  opacity: 0.7;
}

.button_container.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #000000;
}

.button_container.active .middle {
  opacity: 0;
  background: #000000;
}

.button_container.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #000000;
}

.button_container span {
  background: #fff;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
}

.button_container span:nth-of-type(2) {
  top: 11px;
}

.button_container span:nth-of-type(3) {
  top: 22px;
}

.overlay {
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 40;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.overlay nav {
  position: relative;
  height: 70%;
  top: 45%;
  transform: translateY(-50%);
  font-size: 50px;
  font-family: "ravenna", serif;
  font-weight: 400;
  text-align: center;
}

.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}

.overlay li {
  display: block;
  height: 25%;
  height: calc(100% / 4);
  min-height: 50px;
  position: relative;
  opacity: 0;
}

.overlay li a {
  display: block;
  position: relative;
  color: #2d0e7b;
  text-decoration: none;
  overflow: hidden;
}

.overlay li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: #050251;
  transition: 0.35s;
}

.overlay li a:hover:after,
.overlay li a:focus:after,
.overlay li a:active:after {
  width: 100%;
}

.overlay.open li {
  animation: fadeInRight 0.5s ease forwards;
  animation-delay: 0.35s;
}

.overlay.open li:nth-of-type(2) {
  animation-delay: 0.4s;
}

.overlay.open li:nth-of-type(3) {
  animation-delay: 0.45s;
}

.overlay.open li:nth-of-type(4) {
  animation-delay: 0.5s;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
